<template>
  <f-container topBottom>
    <v-card elevation="0">
      <v-card-text>
        <!-- TITLE -->
        <v-row v-if="!isLoading">
          <v-col cols="12" sm="12">
            <v-form ref="form">
              <f-lang-selector :langs="course.langs" />
              <v-tabs-items v-model="selectedLang">
                <v-tab-item v-for="lang in course.langs" :key="lang">
                  <div class="input-spacer">
                    <v-text-field
                      v-model="mdl.title[lang]"
                      :rules="[requireAllLang('title')]"
                      outlined
                      :label="
                        $tt.capitalize($t('labels.title')) +
                          $tt.uppercase(` (${lang})`)
                      "
                      :counter="maxTitleSize"
                    />
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-form>
          </v-col>
        </v-row>

        <!-- DESCRIPTION: removed -->
        <!-- <v-row v-if="!isLoading">
          <v-col cols="12" sm="12">
            <f-lang-selector :langs="course.langs" />
            <v-tabs-items v-model="selectedLang">
              <v-tab-item v-for="lang in course.langs" :key="lang">
                <div class="input-spacer">
                  <v-textarea
                    v-model="mdl.description[lang]"
                    :rules="requiredRule"
                    outlined
                    :label="
                      $tt.capitalize($t('labels.description')) +
                        $tt.uppercase(` (${lang})`)
                    "
                  />
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row> -->
      </v-card-text>
    </v-card>
    <lms-btn-holder>
      <v-btn
        color="primary"
        large
        elevation="0"
        @click="save"
        :disabled="false"
        :loading="false"
      >
        {{ $t('labels.save') }}
      </v-btn>

      <v-btn large depressed @click="back">
        {{ $t('labels.cancel') }}
      </v-btn>
    </lms-btn-holder>
  </f-container>
</template>

<script>
import validatorRules from '../../../helpers/validator_rules';

export default {
  data: () => ({
    currentImage: '',
    tab: 0,
    requiredRule: [],
    maxTitleSize: 100,
    loaded: false,
  }),
  computed: {
    selectedLang() {
      return this.$store.getters['system/selectedInputsLang'];
    },
    course() {
      return this.$store.state.course.current;
    },
    mdl() {
      return this.$store.state.module.current;
    },
  },
  watch: {
    'mdl.title': function(value) {
      if (!this.loaded && typeof value != 'undefined') this.loaded = true;
    },
  },
  methods: {
    async save() {
      const moduleId = this.$route.params.moduleid;
      const action = moduleId ? 'edit' : 'new';

      const valid = this.$refs.form.validate();
      if (!valid) return;

      await this.$store.dispatch('module/save');
      this.back();
      this.$gtm.trackEvent({
        category: 'course-admin-actions',
        event: 'click',
        action: 'course-click',
        label: `${action}-course-module`,
        value: moduleId ? `edit-${moduleId}` : 'new',
      });
    },
    back() {
      this.$router.push({
        name: 'course-view',
        params: {
          id: this.$route.params.courseid,
        },
      });
    },
    requireAllLang(value) {
      let response = false;
      if (validatorRules.checkLangData(this.mdl[value], this.course.langs)) {
        response = true;
      } else {
        response = this.$t('rules.allLangsField');
      }
      return response;
    },
  },
};
</script>
