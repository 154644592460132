<template>
  <div>
    <lms-default-loader v-if="isLoading" />
    <div v-if="!isLoading">
      <f-page-header :title="title" :prevLinks="prevLinks" />

      <f-form />
    </div>
  </div>
</template>

<script>
import FForm from '../../../components/Courses/module/CreateEditForm.vue';

export default {
  components: {
    FForm,
  },
  computed: {
    courseCurrentLanguage() {
      return this.$store.getters['course/courseCurrentLanguage'];
    },
    course() {
      return this.$store.getters['course/current'];
    },
    prevLinks() {
      return [
        {
          title: this.$t('labels.courses'),
          route: {
            name: 'course-list',
          },
        },
        {
          title: this.course.title[this.courseCurrentLanguage],
          route: {
            name: 'course-view',
            params: {
              id: this.course._id,
            },
          },
        },
      ];
    },
    title() {
      let title = this.$t('labels.addModule');
      if (this.$store.getters['system/isEditPage']) {
        title = this.$t('labels.editModule');
      }
      return title;
    },
  },
  created() {
    this.prepareData();
  },
  methods: {
    async prepareData() {
      await this.$store.dispatch('course/get', this.$route.params.courseid);

      if (this.$store.getters['system/isEditPage']) {
        await this.$store.dispatch(
          'module/select',
          this.$route.params.moduleid
        );
      } else {
        this.$store.state.module.current = {
          title: {},
          description: {},
        };
      }

      this.$store.commit('system/pageLoaded');
    },
  },
  beforeDestroy() {
    this.$store.commit('course/clearCurrent');
    this.$store.commit('module/clear');
  },
};
</script>
