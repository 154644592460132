import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f-container',{attrs:{"topBottom":""}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardText,[(!_vm.isLoading)?_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12"}},[_c(VForm,{ref:"form"},[_c('f-lang-selector',{attrs:{"langs":_vm.course.langs}}),_c(VTabsItems,{model:{value:(_vm.selectedLang),callback:function ($$v) {_vm.selectedLang=$$v},expression:"selectedLang"}},_vm._l((_vm.course.langs),function(lang){return _c(VTabItem,{key:lang},[_c('div',{staticClass:"input-spacer"},[_c(VTextField,{attrs:{"rules":[_vm.requireAllLang('title')],"outlined":"","label":_vm.$tt.capitalize(_vm.$t('labels.title')) +
                        _vm.$tt.uppercase(` (${lang})`),"counter":_vm.maxTitleSize},model:{value:(_vm.mdl.title[lang]),callback:function ($$v) {_vm.$set(_vm.mdl.title, lang, $$v)},expression:"mdl.title[lang]"}})],1)])}),1)],1)],1)],1):_vm._e()],1)],1),_c('lms-btn-holder',[_c(VBtn,{attrs:{"color":"primary","large":"","elevation":"0","disabled":false,"loading":false},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('labels.save'))+" ")]),_c(VBtn,{attrs:{"large":"","depressed":""},on:{"click":_vm.back}},[_vm._v(" "+_vm._s(_vm.$t('labels.cancel'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }